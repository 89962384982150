<template>
  <div class="mx-8 my-8">

    <h1 class="text-3xl mb-5">Obras</h1>

    <div class="grid grid-cols-12 gap-6" v-if="$store.state.user.root || $store.state.user.admin">
        <div class="col-span-12 xl:col-span-2 mb-1 mt-1">
          <router-link  :to="`/${route}/form`" type="button" class="transition duration-200 bg-green-500 hover:bg-green-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2  rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center mb-2">
           <span class="inline-block mr-2">Nova Obra</span>
          </router-link>
        </div>
    </div>

    <div class="grid grid-cols-12 gap-6 mb-3">
      <div class="col-span-12 xl:col-span-6 mb-1 mt-1">
          <label for="busca" class="block text-sm font-medium">Buscar Nome</label>
          <input v-model="busca" type="text" name="busca" id="busca" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
      </div>
      <div class="col-span-12 md:col-span-4 mb-1 mt-1">
        <label for="data" class="block text-sm font-medium">Data Início</label>
        <datepicker v-model="dataInicio" :language="ptBR" name="data" input-class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
      </div>
      <div class="col-span-12 md:col-span-2 mb-1 mt-2">
        <label class="block text-sm font-medium">Buscar por filtro </label>
        <button @click="updateFiltro" class="bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2  rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
      </div>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <h4 class="font-bold text-base mb-2">{{ total}} obras cadastradas </h4>
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Empresa
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Nome
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Fase
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Data Início ━ Data Fim
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Controle
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" v-if="$store.state.user.root || $store.state.user.admin">
                    Opções
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="item in list" :key="item._id">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.empresa ? item.empresa.nome : ''}}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.nome ? item.nome : ''}}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.fase ? item.fase : ''}}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.dataInicio | moment("DD/MM/YYYY") }} ━ {{item.dataFim | moment("DD/MM/YYYY") }}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <button @click="$router.push({ path: '/agualuz', query: { obra: item._id } })" class="text-sm text-white hover:bg-blue-500 bg-blue-700 rounded py-2 px-2 mx-1">
                      Registro água e luz
                    </button>
                    <br>
                    <button @click="$router.push({ path: '/residuos', query: { obra: item._id } })" class="mt-2 text-sm text-white hover:bg-yellow-400 bg-yellow-500 rounded py-2 px-2 mx-1">
                      Saída Resíduos
                    </button>
                    <br>
                    <button @click="$router.push({ path: '/qtdfunc', query: { obra: item._id } })" class="mt-2 text-sm text-white hover:bg-blue-400 bg-blue-500 rounded py-2 px-2 mx-1">
                      Quantidade de Funcionários
                    </button>
                  </td>
                 
                  <td class="px-6 py-4 whitespace-nowrap text-sm font-medium" v-if="$store.state.user.root || $store.state.user.admin">

                    <router-link 
                    :to="`/${route}/form/${item._id}`"
                    class="text-blue-500 rounded py-1 px-4">
                    Editar
                    </router-link>

                    <button @click="remove(item._id)" class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-4 mx-1">
                      Excluir
                    </button>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale'
export default {
  data() {
    return {
      ptBR: ptBR,
      busca: '',
      dataInicio: '',
      route: 'obras',
      list: [],
      perPage: 10,
      query: { page: 1, limit: 10, skip: 0  },
      total: 0,
    }
  },
  methods: {

    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, {  busca: this.busca, dataInicio: this.dataInicio, skip: this.query.skip, limit: this.query.limit, });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },
    
    async remove(id) {
      if (confirm("Tem certeza que deseja excluir essa obra?")) {
        await this.$http.delete(`/v1/${this.route}/${id}`);
        this.$vToastify.success("Removido com sucesso!");
        this.start();
      }
    },

    updateFiltro(){
      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { page: this.query.page, limit: this.query.limit, skip: this.query.skip, busca: this.busca, dataInicio: moment(this.dataInicio).toISOString() };
      this.$router.push({ path: `/${this.route}`, query: this.query });
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;

      this.$router.push({ path: `/${this.route}`, query: this.query });
    }
  },
  components: {
    Datepicker
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.dataInicio) this.dataInicio = this.$route.query.dataInicio;
    if(this.$route.query.busca) this.busca = this.$route.query.busca;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async beforeMount() {

    if(this.$route.query.dataInicio) this.dataInicio = this.$route.query.dataInicio;
    if(this.$route.query.busca) this.busca = this.$route.query.busca;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
}
</script>